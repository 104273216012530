<template>
  <div>
    <div >
      <AppMain />
    </div>
  </div>
</template>

<script>

import AppMain from "../../components/Layout/AppMain.vue";

export default {
  name: "AnswerLayout",
  components: {
    AppMain,
  },
  data() {
    return {};
  },
};
</script>
