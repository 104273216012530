import Vue from "vue";
import Vuex from "vuex";
import { getQuestions, getQuestionsWithAnswers } from "../../apiFunction.js";
import store from "../index.js";
Vue.use(Vuex);
const getDefaultState = () => {
  return {
    questionAPIStatus: false,
    // Total No. of Attempted Answers
    countLength: "",
    status: "",
    noPage: 1,
    // Total No. of Attempted Answers
    attemptedCount: 0,
    // Stores All the Questions and Answers
    questionData: {},

    // Stores the Current Answer
    currentQuestionData: {},

    // Stores the All Answer
    answerData: {
      testID: "",
      topicID: "",
      testResponse: [],
    },
    // Storing Section WISE Answer Data
    testResponse: [],

    // Preloader
    isLoadingStatusOne: false,

    // QuestionAnswerStatus
    questionAnswerStatus: true,
    pageItem: [],
    allItem: [],
    lastQuestionIndex: 0,
    noOfQues: 0,
    noOfPages: 0,
  };
};
// initial state
const state = getDefaultState();

const getters = {
  getCountLengthnData: (state) => state.countLength,
  getAnswerData: (state) => state.answerData,
  getNoPage: (state) => state.noPage,
  getQuestionData: (state) => state.questionData,
  getAttemptedAnswersCount: (state) => state.attemptedCount,
  getCurrentQuestionData: (state) => state.currentQuestionData,
  getiIsLoadingStatusOne: (state) => state.isLoadingStatusOne,
  getTestResponse: (state) => state.testResponse,
  getPageItem: (state) => state.pageItem,
  getNoOfPages: (state) => state.noOfPages,
  getallItem: (state) => state.allItem,
  getNoOfQues: (state) => state.noOfQues,
  getLastQuestionIndex: (state) => state.lastQuestionIndex,
  getQuestionAnswerStatus: (state) => state.questionAnswerStatus,
};

const actions = {
  async saveQuestionAPIStatus(state, payload) {
    state.commit("saveQuestionAPIStatus", payload);
  },

  async getQuestions(state, payload) {
    state.commit("isLoadingStatusOne", true);
    let data = {
      testID: payload.testID,
      topicID: payload.topicID,
      sectionID: payload.sectionID,
    };
    getQuestions(data)
      .then((res) => {
        let sectionID = payload.sectionID;
        state.commit("checkstatus", res.status);
        // Sending the Data as
        let data = {};
        data[sectionID] = [];
        state.commit("initializeTestReponse", {
          sectionID: payload.sectionID,
          sectionResponse: [],
        });
        let answerStatus = {
          active: false,
          later: false,
          unattempted: true,
          attempted: false,
        };
        if (res.status == 200) {
          for (let i in res.data.data.questionsBody) {
            let temp = res.data.data.questionsBody[i];
            let x = temp.questionKey.substring(0, 4);
            if (x == "MAFO") {
              let sideB = [];
              for (let j in res.data.data.questionsBody[i].answerKey) {
                sideB.push(res.data.data.questionsBody[i].answerKey[j].sideB);
              }
              res.data.data.questionsBody[i].answer = sideB;
            }
            res.data.data.questionsBody[i].answerStatus = { ...answerStatus };
            data[sectionID].push(res.data.data.questionsBody[i]);
          }
          state.commit("saveAllQuestionData", data);
          state.commit("answerCount");
          setTimeout(() => {
            state.commit("isLoadingStatusOne", false);
          }, 500);
          state.commit("questionAnswerStatus", false);
          let countingBro = res.data.data.questionCount;
          state.commit("savecountLength", countingBro);
        } else {
          state.commit("saveAllQuestionData", data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getQuestionsWithAnswers(state, payload) {
    let data = {
      topicID: store.state.sectionVUEX.testDetails.topicID,
      testID: payload.testID,
      sectionID: payload.sectionID,
    };
    getQuestionsWithAnswers(data)
      .then((res) => {
        state.commit("checkstatus", res.status);
        let data1 = res.data.data.questionsBody;
        for (let i in data1) {
          if (!("answerStatus" in data1[i])) {
            data1[i].answerStatus = {
              active: false,
              later: false,
              unattempted: true,
              attempted: false,
            };
          }
          if (!("answer" in data1[i])) {
            let temp = data1[i];
            let x = temp.questionKey.substring(0, 4);
            if (x == "MAFO") {
              let sideB = [];
              for (let j in data1[i].answerKey) {
                sideB.push(data1[i].answerKey[j].sideB);
              }
              data1[i].answer = sideB;
            }
          }
        }
        let savedata = { [data.sectionID]: data1 };
        state.commit("initializeAnswerDataQuesWithAns", data1);
        state.commit("saveAllQuestionData", savedata);
        state.commit("answerCount");
        state.commit("sectionAnswerCount");
        let countingBro = res.data.data.questionCount;
        state.commit("savecountLength", countingBro);
      })
      .catch((err) => {
        if (err.response.status == 409) {
          state.dispatch("getQuestions", payload);
        }
      });
  },
};

// mutations
const mutations = {
  RESET_ALL_STATE(state){
    state.questionAPIStatus= false
    state.countLength= ""
    state.status= ""
    state.noPage= 1
    state.attemptedCount= 0
    state.questionData= {}
    state.currentQuestionData= {}
    state.answerData= {
      testID: "",
      topicID: "",
      testResponse: []
    }
    state.testResponse= []
    state.isLoadingStatusOne= false
    state.questionAnswerStatus= true
    state.pageItem= []
    state.allItem= []
    state.lastQuestionIndex= 0
    state.noOfQues= 0
    state.noOfPages= 0
  },
  saveNoPage(state, payload) {
    if (payload == "set") {
      state.noPage = 1;
    } else if (payload == "next") {
      state.noPage += 1;
    } else if (payload == "prev") {
      state.noPage -= 1;
    } else {
      state.noPage = payload;
    }
  },
  savecountLength(state, payload) {
    state.countLength = payload;
  },
  checkstatus(state, payload) {
    state.status = payload;
  },
  pageItems(state, payload) {
    state.pageItem = payload;
  },
  allItem(state, payload) {
    state.allItem = payload;
  },
  noOfQues(state, payload) {
    state.noOfQues = payload;
  },
  lastQuestionIndex(state, payload) {
    state.lastQuestionIndex = payload;
  },
  noOfPages(state, payload) {
    state.noOfPages = payload;
  },

  answerCount(state) {
    let sectionID = store.state.sectionVUEX.selectedSection.sectionID;
    for (let i in state.testResponse) {
      if (state.testResponse[i].sectionID === sectionID) {
        state.attemptedCount = state.testResponse[i].sectionResponse.length;
        break;
      } else {
        state.attemptedCount = 0;
      }
    }
  },
  sectionAnswerCount(state) {
    for (let i in store.state.sectionVUEX.sectionDetails) {
      let sectionID = store.state.sectionVUEX.sectionDetails[i].sectionID;
      for (let j in state.testResponse) {
        let vai = 0;
        if (state.testResponse[j].sectionID === sectionID) {
          for (let k in state.testResponse[j].sectionResponse) {
            if (
              state.testResponse[j].sectionResponse[k].answerStatus.attempted
            ) {
              vai = vai + 1;
              store.state.sectionVUEX.sectionDetails[i].attemptedCount = vai;
            }
          }
          break;
        }
      }
    }
    mutations.sectAnsCount();
  },
  sectAnsCount() {
    for (let sec in store.state.sectionVUEX.sectionDetails) {
      if (
        store.state.sectionVUEX.sectionDetails[sec].sectionID ==
        store.state.sectionVUEX.selectedSection.sectionID
      ) {
        store.state.sectionVUEX.selectedSection.attemptedCount =
          store.state.sectionVUEX.sectionDetails[sec].attemptedCount;
      }
    }
    let count = 0;
    for (let p in store.state.sectionVUEX.sectionDetails) {
      count += store.state.sectionVUEX.sectionDetails[p].attemptedCount;
      store.state.sectionVUEX.totalanswered.attemptedQuestions = count;
    }
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  saveQuestionAPIStatus(state, payload) {
    state.questionAPIStatus = payload;
  },

  saveAllQuestionData(state, payload) {
    state.questionData = Object.assign(state.questionData, payload);
  },

  // Called while CREATING sections to add the set of questions
  initializeTestReponse(state, payload) {
    if (Object.keys(state.testResponse).length === 0) {
      state.testResponse.push(payload);
    } else {
      const found = state.testResponse.find(
        (element) => element.sectionID === payload.sectionID
      );
      if (found === undefined) {
        state.testResponse.push(payload);
      }
    }
  },

  // Called while editing a particular answer to store each and every particular change
  saveCurrentAnswer(state, payload) {
    state.currentQuestionData = { ...payload };
  },

  // Creates the PAYLOAD for the answer data
  initializeAnswerData(state, payload) {
    state.answerData.testID = store.state.sectionVUEX.testDetails.testID;
    state.answerData.topicID = store.state.sectionVUEX.testDetails.topicID;
    let sectionID = store.state.sectionVUEX.selectedSection.sectionID;
    let data = { questionKey: "", answer: "", answerStatus: {} };
    for (let i in state.testResponse) {
      if (state.testResponse[i].sectionID === sectionID) {
        data.questionKey = payload.questionKey;
        const isObject = (value) => typeof value === "object" && value !== null;
        if (isObject(payload.answer)) {
          data.answer = { ...payload.answer };
          data.answerStatus = { ...payload.answerStatus };
        } else {
          data.answer = payload.answer;
          data.answerStatus = { ...payload.answerStatus };
        }
        mutations.initTestResponse(state.testResponse[i], data);
      }
    }
    state.answerData.testResponse = state.testResponse;
  },

  initTestResponse(testRes, data) {
    if (testRes.sectionResponse.length === 0) {
      testRes.sectionResponse.push(data);
      store.state.sectionVUEX.selectedSection.attemptedCount += 1;
      store.state.sectionVUEX.totalanswered.attemptedQuestions += 1;
    } else {
      let index = testRes.sectionResponse.findIndex(
        (i) => i.questionKey === data.questionKey
      );
      if (index > -1) {
        testRes.sectionResponse.splice(index, 1, data);
      } else {
        testRes.sectionResponse.push(data);
        store.state.sectionVUEX.selectedSection.attemptedCount += 1;
        store.state.sectionVUEX.totalanswered.attemptedQuestions += 1;
      }
    }
  },

  // For Loading
  isLoadingStatusOne(state, payload) {
    state.isLoadingStatusOne = payload;
  },
  initializetestresponseQuesWithAns(state) {
    state.testResponse = [];
    for (let i in store.state.sectionVUEX.sectionDetails) {
      let data = {
        sectionID: "",
        sectionResponse: [],
      };
      data.sectionID = store.state.sectionVUEX.sectionDetails[i].sectionID;
      state.testResponse.push(data);
    }
  },
  // initialize answer data after get questions with answer
  initializeAnswerDataQuesWithAns(state, payload) {
    let questions = payload;
    state.answerData.testID = store.state.sectionVUEX.testDetails.testID;
    state.answerData.topicID = store.state.sectionVUEX.testDetails.topicID;
    let sectionID = store.state.sectionVUEX.selectedSection.sectionID;
    let data = { questionKey: "", answer: "", answerStatus: {} };
    for (let j in state.testResponse) {
      for (let i in questions) {
        if (state.testResponse[j].sectionID === sectionID) {
          if (questions[i].answerStatus.attempted) {
            data.questionKey = questions[i].questionKey;
            mutations.questionsData(questions[i], data);
            mutations.questionWithAnsData(state.testResponse[j], data);
            data = { questionKey: "", answer: "", answerStatus: {} };
          }
        }
      }
    }
    state.answerData.testResponse = state.testResponse;
  },
  questionsData(quest, data) {
    const isObject = (value) => typeof value === "object" && value !== null;
    if (isObject(quest.answer)) {
      data.answer = { ...quest.answer };
      data.answerStatus = { ...quest.answerStatus };
    } else {
      data.answer = quest.answer;
      data.answerStatus = { ...quest.answerStatus };
    }
  },
  questionWithAnsData(res, data) {
    if (res.sectionResponse.length === 0) {
      res.sectionResponse.push(data);
    } else {
      let checkKey = Object.values(res.sectionResponse);
      let sampleKey;
      for (let i in checkKey) {
        sampleKey = Object.values(checkKey[i]);
        let testKey = data.questionKey;
        if (testKey === sampleKey[0]) {
          break;
        } else {
          continue;
        }
      }
      if (sampleKey[0].includes(data.questionKey)) {
        mutations.sectionResponse(res.sectionResponse, data);
      } else {
        res.sectionResponse.push(data);
      }
    }
  },
  sectionResponse(response, data) {
    for (let i in response) {
      if (response[i].questionKey === data.questionKey) {
        response[i] = { ...data };
      }
    }
  },

  questionAnswerStatus(state, payload) {
    state.questionAnswerStatus = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
