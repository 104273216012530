import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const state = () => ({
    isConnected: false,
    socketMessage: ''
});

const getters = {
  getSocketConnectionStatus: state => state.isConnected,
  getSocketMessage: state => state.socketMessage
};

// mutations
const mutations = {
  RESET_ALL_STATE(state){
    state.isConnected= false
    state.socketMessage= ''
  },
  SOCKET_CONNECT(state) {
    state.isConnected = true;
  },

  SOCKET_DISCONNECT(state) {
    state.isConnected = false;
  },

  SOCKET_MESSAGE(state, message) {
    state.socketMessage = message
  }
};

export default {
  namespaced: true,
  state,
//   actions,
  mutations,
  getters
};
