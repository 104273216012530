  const {
    getSysToken,
    getUserTokenBypass,
    getCandidateLogin,
    getIds,
    verifyUser,
  } = require('../apiFunction.js')
  const jwt_decode = require( 'jwt-decode').default
  const store = require( '../store').default
const eventBus=require('../utils/eventBus.js').default

        const  onSave = (token,uuid, router)=> {
        const accesstoken = jwt_decode(token);

        console.log(accesstoken.email,"accesstoken.email")
          let data = {
            firstName: accesstoken.given_name,
            email: accesstoken.email,
            enabled: true,
          };
  
          verifyUser(data).then((res) => {
            if (res.status == 200) {

              console.log(res);
              store.commit("sectionVUEX/saveUserDetails", data);
              store.commit("sectionVUEX/saveUUID", uuid);
              getUserTokenBypass(accesstoken.email)
                .then(async (res1) => {
                  localStorage.setItem("vue_token", res1.data.token);
                  console.log(res1,"from verifyuser")
                  await getTestDetails(accesstoken,res.data.data[0].ID,uuid, router);

                })
                .catch((err) => {
                  console.log("ERROR on createUser ----" + err);
                });
            }
          });
      };
   const goToTestDetails=(uuid, router)=> {
        localStorage.setItem("isLoggedIn", true);
        router.replace({
          path: "/details",
          query: {
            uuid: uuid,
          },
        });
      };

     const getTestDetails= async (accesstoken,userID,uuid, router)=> {
        try {
          localStorage.removeItem("simpCandidState");
          const res = await getIds(uuid);
          if (res.status == 200) {
            let testID = res.data.data.testID;
            let topicID = res.data.data.topicID;
            let groupID = res.data.data.groupID;
            store.commit("sectionVUEX/saveUserID", getToken().sub);
            console.log(accesstoken.email,"gettestdeet")
            const res1 = await getCandidateLogin(
              topicID,
              testID,
              groupID,
              uuid,
              userID,
              accesstoken.email
            );
            console.log(res1,"res1")
            if (res1.status == 200) {
              var testData = {};
              testData = res1.data.data.testID;
              testData.config = res1.data.data.configID;
              testData.testID = testID;
              testData.topicID = topicID;
              testData.groupID = res.data.data.groupID;
              testData.topicName = res1.data.data.topicName;
              testData.attemptNo = res1.data.data.attemptNo;
              testData.jobID=res1.data.data.jobID;
              testData.customerName=res1.data.data.customerName;
              testData.jobName=res1.data.data.jobName;
              store.commit("sectionVUEX/saveTestDetails", testData);
              store.commit(
                "sectionVUEX/savelicensekey",
                res1.data.data.licenseKey
              );
              goToTestDetails(uuid, router);
             }
             else if (res1.status == 203) {
              eventBus.$emit('show-modal', res1.data.data.Detail);
            } 
            else{
              eventBus.$emit('show-modal', res1.data.data.Detail);
            }
          } 
        } catch (error) {
              eventBus.$emit('show-modal', error.response.data.data.Detail);
        }
      }
  
      const getToken= async ()=> {
        try {
          const res = await getSysToken();
          localStorage.setItem("KEYCLOAK_IDENTITY_LEGACY", res.data.token);
          return jwt_decode(res.data.token);
        } catch (error) {
          console.log(error);
        }
    };

  module.exports={
    onSave
  };
  