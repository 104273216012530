import jwt_decode from "jwt-decode";
import {
  serviceLicense,
  serviceCandidate,
  serviceCandidatev2,
  serviceMapping,
  serviceQuestion,
  SysToken,
  KeycloakAPIs,
  serviceagent,
  serviceProctoring,
  serviceShare,
  coderequest,
  imageVerify,
  serviceCandidateFakeToken,
} from "./request.js";

export function codecompileforeachcase(data) {
  return coderequest({
    url: `compile-test-case/`,
    method: "post",
    data,
  });
}

// For license
imageVerify.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();

    body.headers["userID"] = getProcessedToken();

    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);
serviceLicense.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();


    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);
coderequest.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();

    body.headers["userID"] = "5b935330-0f65-4492-b158-5e3e7b9b8bd4";

    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);
serviceShare.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();

    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

// For Candidate Mapping
serviceMapping.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();
    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

// For Questions
serviceQuestion.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();

    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

function getToken() {
  return localStorage.getItem("vue_token");
}
function getProcessedToken() {
  let decoded = jwt_decode(localStorage.getItem("vue_token"));
  return decoded.sub;
}

// function getProcessedToken() {
//   // const token = localStorage.getItem("vue_token");
//   const decoded = jwt_decode(localStorage.getItem("vue_token"));
//   return decoded.sub;
// }
// For Answers

serviceCandidate.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();

    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);
serviceCandidatev2.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();
    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

serviceProctoring.interceptors.request.use(
  (body) => {
    body.headers["Authorization"] = "Bearer " + getToken();
    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

serviceagent.interceptors.request.use(
  (body) => {
    return body;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);
export function checkimageresponse(data) {
  return imageVerify({
    url: `face_detection/`,
    method: "post",
    data,
  });
}

export function getIds(data) {
  return serviceShare({
    url: `v2/LinkGen/?uuid=` + data,
    method: "get",
  });
}

export function getInternetAndSystemCheck(uuid, token) {
  return serviceCandidateFakeToken({
    url: `system-check/?uuid=` + uuid,
    method: "get",
    headers: {'Authorization' : 'Bearer ' + token},
  });
}

export function getTestOrg(
  topicID,
  testID,
  uuid,
  candidateID,
  candidateEmail,
  header
) {
  return serviceLicense({
    url:
      `TestOrg/?topicID=` +
      topicID +
      "&testID=" +
      testID +
      "&uuid=" +
      uuid +
      "&candidateID=" +
      candidateID +
      "&candidateEmail=" +
      candidateEmail,
    method: "get",
    headers: header,
  });
}
export function getCandidateLogin(
  topicID,
  testID,
  groupID,
  uuid,
  candidateID,
  candidateEmail,
  header
) {
  return serviceCandidate({
    url:
      `candidateLogin/?topicID=` +
      topicID +
      "&testID=" +
      testID +
      "&groupID=" +
      groupID +
      "&uuid=" +
      uuid +
      "&candidateID=" +
      candidateID +
      "&candidateEmail=" +
      candidateEmail,
    method: "get",
    headers: header,
  });
}
// /can/candidateTestTimer/
export function posttimer(data) {
  return serviceCandidate({
    url: `/candidateTestTimer/`,
    method: "post",
    data,
  });
}

// GET ALL Questions
export function getQuestions(data) {
  return serviceQuestion({
    url: `getQuestions/?testID=${data.testID}&topicID=${data.topicID}&sectionID=${data.sectionID}`,
    method: "get",
  });
}

// GET ALL getQuestionsWithAnswers
export function getQuestionsWithAnswers(data) {
  return serviceQuestion({
    url:
      `getQuestionsWithAnswers/` +
      `?topicID=` +
      data.topicID +
      `&testID=` +
      data.testID +
      `&sectionID=` +
      data.sectionID,
    method: "get",
  });
}

// /compiler-with-single-testcase/
export const getcodesnippetcompilertestCase = (data) => {
  return coderequest({
    url: "compiler-with-single-testcase/",
    method: "post",
    data,
  });
};

export const testCaseRunner = (data) => {
  return coderequest({
    url: "compiler-with-testcase/",
    method: "post",
    data,
  });
};
//KeyCloakAPIs
export function getSysToken() {
  return SysToken({
    url: `getSysToken`,
    method: "get",
  });
}
// fake token
export function tokenForUserMobileLogin() {
  return SysToken({
    url: `fakejwttoken`,
    method: "get",
  });
}
export function verifyUser(data) {
  return SysToken({
    url: `user`,
    method: "post",
    data,
  });
}
export const getcodesnippetcompiler = (data) => {
  return coderequest({
    url: "compiler/",
    method: "post",
    data,
  });
};
// /can/v2/save-code/
export const savecodesnppet = (data) => {
  return serviceCandidatev2({
    url: "save-code/",
    method: "put",
    data,
  });
};
export function verifyemail(email) {
  return SysToken({
    url: `verifyemail/?email=` + email,
    method: "get",
  });
}

export function getUserTokenBypass(email) {
  return SysToken({
    url: `getUserToken/?email=` + email,
    method: "get",
  });
}

export function createUser(data, header) {
  return KeycloakAPIs({
    url: `/admin/realms/hire3x-dev/users`,
    method: "post",
    headers: header,
    data,
  });
}

export function getUserToken(data) {
  return KeycloakAPIs({
    url: `/realms/hire3x-dev/protocol/openid-connect/token`,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data,
  });
}

// Answer

// Post Answers
// export function postAnswer(data) {
//   return serviceCandidate({
//     url: `candidateTestResponse/`,
//     method: "post",
//     data,
//   });
// }

// GET Candidate Timer
export function getTestTimer(data, configID, groupID, attemptNo) {
  return serviceCandidate({
    url:
      `candidateTestTimer/?testID=` +
      data +
      "&groupID=" +
      groupID +
      "&configID=" +
      configID +
      "&attemptNo=" +
      attemptNo,
    method: "get",
  });
}

export function candidateImage(data) {
  return serviceCandidate({
    url: `capturecandidateimage/`,
    method: "post",
    data,
  });
}

// For Candidate Start And End Test Time
// export function getCandidateTestTiming(topicID,testID) {
//   return serviceCandidate({
//     url: `candidateTestResponse/?topicID=`+topicID+`&testID=`+testID,
//     method: "post",
//     data,
//   });
// }
export function getCandidateTestTiming(topicID, testID) {
  return serviceCandidate({
    url: `candidateTestResponse/?topicID=` + topicID + `&testID=` + testID,
    method: "get",
  });
}

// Post Test with geolocation
export function candidateteststatsPost(data) {
  return serviceCandidatev2({
    url: `candidateteststats/`,
    method: "post",
    data,
  });
}

export function putAnswer(data) {
  return serviceCandidatev2({
    url: `candidateTestResponse/`,
    method: "put",
    data,
  });
}

// End Test APi
export function endTestPost(data) {
  return serviceCandidatev2({
    url: `endTest/`,
    method: "post",
    data,
  });
}
// marking
export function postMarking(data) {
  return serviceCandidatev2({
    url: `marking/`,
    method: "post",
    data,
  });
}
// agent get api

export function getagentdetails(data) {
  return serviceagent({
    url: `agent/system-check/?attemptNo=${data}`,
    method: "get",
  });
}
// agent post api

export function startTestAgent(data) {
  return serviceagent({
    url: `agent/start-test/`,
    method: "post",
    data,
  });
}
// agent post api

export function stopTestAgent() {
  return serviceagent({
    url: `stop-test/`,
    method: "post",
  });
}

// agent Check

export function CheckAgentService(data) {
  return serviceagent({
    url: `agent-check/?attemptNo=${data}`,
    method: "get",
  });
}
// syatem info api
export function getSystemInfo() {
  return serviceagent({
    url: `agent/system-info`,
    method: "get",
  });
}
export function getAnsweredCount(data) {
  return serviceCandidatev2({
    url:
      `answeredQues/?topicID=` +
      data.topicID +
      `&testID=` +
      data.testID +
      `&groupID=` +
      data.groupID +
      `&attemptNo=` +
      data.attemptNo,
    method: "get",
  });
}

export function postExtensionLog(data) {
  return serviceProctoring({
    url: `extension/`,
    method: "post",
    data,
  });
}

export function getSharePageData(data) {
  return serviceShare({
    url: `ShareOrg/` + `?testID=` + data,
    method: "get",
  });
}

export function postCandidateRemarks(data) {
  return serviceCandidatev2({
    url: `addRemarks/`,
    method: "post",
    data,
  });
}

export function downloadAgent(data) {
  return serviceCandidate({
    url: `downloadAgent/`,
    method: "get",
    params: {
      platform: data,
    },
  });
}

export function getTestResponse(
  topicID,
  testID,
  groupID,
  candidateID,
  attemptNo
) {
  return serviceCandidatev2({
    url: `testResponse/?topicID=${topicID}&testID=${testID}&groupID=${groupID}&candidateID=${candidateID}&attemptNo=${attemptNo}`,
    method: "get",
  });
}

export function postTypingResult(data) {
  return serviceCandidatev2({
    url: `typingAnalysis/`,
    method: "post",
    data,
  });
}

export function getTypingResult(topicID, testID, email, attemptNo, groupID) {
  return serviceCandidatev2({
    url: `typingAnalysisCheck/`,
    method: "get",
    params: {
      topicID: topicID,
      testID: testID,
      email: email,
      attemptNo: attemptNo,
      groupID: groupID,
    },
  });
}
// ---------To check weather user can able to take test in mobile or  not
export function candidateSystemCheck(uuid) {
  return serviceCandidatev2({
    url: `mobileCheck/${uuid}`,
    method: "get",
  });
}

//communication TEST check
// {{assessmentLocalBaseURL}}/can/v2/
export function getCommunicationTestCompletion(
  topicID,
  testID,
  attemptNo,
  groupID
) {
  return serviceCandidatev2({
    url: `communication-completion-status/?topicID=${topicID}&testID=${testID}&attemptNo=${attemptNo}&groupID=${groupID}`,
    method: "get",
  });
}
// 127.0.0.1:8000/can/v2/communication-test-check/?topicID=L071222A001C009&testID=L071222A001C009T019&email=go@go.goo&attemptNo=7&groupID=L071222A001GRP001
export function getCommunicationTestDetails(
  topicID,
  testID,
  email,
  attemptNo,
  groupID
) {
  return serviceCandidatev2({
    url: `communication-test-check/`,
    method: "get",
    params: {
      topicID: topicID,
      testID: testID,
      email: email,
      attemptNo: attemptNo,
      groupID: groupID,
    },
  });
}

//127.0.0.1:8000/can/v2/communication-test-response/
export function postCommunicationTestTesponse(data) {
  return serviceCandidatev2({
    url: `communication-test-response/`,
    method: "post",
    data,
  });
}
