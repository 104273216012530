<template>
  <div>
    <div id="app">
      {{ stateSync }}
      <router-view />
    </div>
    <Internet />
    <LoginModals />
  </div>
</template>

<script>
import Internet from "./components/fixedcomponents/Internet.vue";
import LoginModals from "./components/modals/LoginToastModals.vue"
export default {
  name: "App",
  components: {
    Internet,
    LoginModals
  },
  data() {
    return {};
  },
  computed: {
    stateSync() {
      let stt = this.$store.state;
      localStorage.setItem("simpCandidState", JSON.stringify(stt));
      return "";
    },
  },
  created() {
    if (!this.fnBrowserDetect()) {
      this.$router.push({
        path: "/unsupportedbrowser",
      });
    }
    let savedstate = localStorage.getItem("simpCandidState");
    if (savedstate != null) {
      this.$store.replaceState(JSON.parse(savedstate));
    }
  },

  methods: {
    fnBrowserDetect() {
      console.log(navigator)
      var isChrome = false;
      const { userAgent } = navigator;
      if (userAgent.includes("Firefox/")) {
        isChrome = false;
      } else if (userAgent.includes("Edg/")) {
        isChrome = false;
      } else if (userAgent.includes("Chrome/")) {
        isChrome = true;
      } else if (userAgent.includes("Opera/")) {
        isChrome = false;
       }
       //else if (userAgent.includes("Safari/")) {
      //   isChrome = false;
      // }
       else {
        isChrome = true;
      }

      return isChrome;
    },
  },
};
</script>