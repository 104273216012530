import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout/Layout";
import AnswerLayout from "../views/layout/AnswerLayout";
import Modal, { BModal } from "bootstrap-vue";
import {onSave} from "../mixins/LoginFromCand"
import {
  getInternetAndSystemCheck,
  tokenForUserMobileLogin,
} from "../apiFunction";
import store from "../store";
Vue.component("b-modal", BModal);
Vue.use(Modal);
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "remarks",
        name: "Remarks",
        component: () => import("@/views/Remarks.vue"),
        meta: {
          title: "RemarksPage",
        },
      },
      {
        path: "internetcheck",
        name: "internetSpeed",
        component: () => import("@/views/InternetSpeed.vue"),
        meta: {
          title: "internetSpeed",
        },
      },
      {
        path: "login",
        name: "StudentLogin",
        component: () => import("../views/StudentLogin.vue"),
        meta: {
          title: "Login",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "details",
        name: "TestDetails",
        component: () => import("../views/TestDetails.vue"),
        meta: {
          title: "TestDetails",
          test: true,
          disallowAuthed: true,
        },
      },

      {
        path: "snapshots",
        name: "snapshots",
        component: () => import("../views/Snapshot.vue"),
        meta: {
          title: "snapshots",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "dosanddonts",
        name: "DoAndDont",
        component: () => import("../views/DosandDonts.vue"),
        meta: {
          title: "DoAndDont",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "systempermission",
        name: "SystemCheck",
        component: () => import("../views/SystemCheck.vue"),
        meta: {
          title: "SystemCheck",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "payment",
        name: "Payment",
        component: () => import("../components/Payment.vue"),
        meta: {
          title: "Payment",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "typingtest",
        name: "TypingTest",
        component: () => import("../components/TypingTest.vue"),
        meta: {
          title: "TypingTest",
        },
      },
      {
        path: "communicationtest",
        name: "communicationtest",
        component: () => import("../components/CommunicationTest.vue"),
        meta: {
          title: "communicationtest",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "extension",
        name: "ExtensionInstall",
        component: () => import("../components/ExtensionInstall.vue"),
        meta: {
          title: "ExtensionInstall",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "AgentandExtension",
        name: "AgentandExtension",
        component: () => import("../components/AgentandExtension.vue"),
        meta: {
          title: "AgentandExtension",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "unsupportedbrowser",
        name: "Unsupportedbrowser",
        component: () => import("../components/Unsupportedbrowser.vue"),
        meta: {
          title: "Unsupportedbrowser",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "agent",
        name: "AgentExtension",
        component: () => import("../components/Agent.vue"),
        meta: {
          title: "AgentExtension",
          test: true,
          disallowAuthed: true,
        },
      },
      {
        path: "finishtest",
        name: "FinishTest",
        component: () => import("../components/FinishTest.vue"),
        meta: {
          title: "FinishTest",
        },
      },
      {
        path: "testclosed",
        name: "TestClosed",
        component: () => import("../components/TestClosed.vue"),
        meta: {
          title: "TestClosed",
        },
      },
      {
        path: "instantfinishtest",
        name: "InstantFinishTest",
        component: () => import("../components/InstantFinishTest.vue"),
        meta: {
          title: "InstantFinishTest",
        },
      },
    ],
  },
  {
    path: "/",
    component: AnswerLayout,
    children: [
      {
        path: "testScreen",
        name: "TestScreen",
        component: () => import("../views/TestScreen.vue"),
        meta: {
          title: "TestScreen",
          disallowAuthed: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: AnswerLayout,
    children: [
      {
        path: "mlogin",
        name: "MStudentLogin",
        component: () =>
          import("../components/mobileView/MobileStudentLogin.vue"),
        meta: {
          title: "MStudentLogin",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MTestDetails",
        name: "MTestDetails",
        component: () =>
          import("../components/mobileView/MobileTestDetails.vue"),
        meta: {
          title: "MTestDetails",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MDosDonts",
        name: "MDosDonts",
        component: () => import("../components/mobileView/MobileDosDonts.vue"),
        meta: {
          title: "MDosDonts",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MSystemCheck",
        name: "MSystemCheck",
        component: () => import("../components/mobileView/MsystemCheck.vue"),
        meta: {
          title: "MSystemCheck",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MSnapshot",
        name: "MSnapshot",
        component: () => import("../components/mobileView/MobileSnapshot.vue"),
        meta: {
          title: "MSnapshot",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MIdCardShot",
        name: "MIdCardShot",
        component: () =>
          import("../components/mobileView/MobileIdCardShot.vue"),
        meta: {
          title: "MIdCardShot",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MTestScreen",
        name: "MTestScreen",
        component: () =>
          import("../components/mobileView/MobileTestScreen.vue"),
        meta: {
          title: "MTestScreen",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MInternetCheck",
        name: "MInternetCheck",
        component: () =>
          import("../components/mobileView/MobileInternetCheck.vue"),
        meta: {
          title: "MInternetCheck",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MNewInternetCheck",
        name: "MNewInternetCheck",
        component: () =>
          import("../components/mobileView/MobileNewInternetCheck.vue"),
        meta: {
          title: "MNewInternetCheck",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MFeedback",
        name: "MFeedback",
        component: () => import("../components/mobileView/MFeedback.vue"),
        meta: {
          title: "MFeedback",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/Mcompleted",
        name: "Mcompleted",
        component: () => import("../components/mobileView/Mcompleted.vue"),
        meta: {
          title: "Mcompleted",
          disallowAuthed: true,
          test: true,
        },
      },
      {
        path: "/MTclosed",
        name: "MTclosed",
        component: () => import("../components/mobileView/MTclosed.vue"),
        meta: {
          title: "MTclosed",
          disallowAuthed: true,
          test: true,
        },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const checkForStartAndFinishTest = (to, next) => {
  const testStarted = localStorage.getItem("testStarted");
  const testFinished = localStorage.getItem("testFinished");
  if (testStarted && to.meta.test) {
    if (to.meta.title != "Login") {
      next(false);
      alert("You cannot navigate while you're on test!");
    }
    return { name: "TestScreen" };
  }
  if (testFinished && (to.meta.disallowAuthed || to.meta.test)) {
    return { name: "FinishTest" };
  }
};
const checkBeforeRouting = (to, next) => {
  const testStarted = localStorage.getItem("testStarted");
  const testFinished = localStorage.getItem("testFinished");
  const doDont = localStorage.getItem("doDont");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  let timer = localStorage.getItem("timer");
  let vue_token = localStorage.getItem("vue_token");
  if (to.meta.title === "Login") {
    if (timer) {
      timer = true;
    } else {
      timer = false;
    }
    if (vue_token) {
      vue_token = true;
    } else {
      vue_token = false;
    }
    console.log(timer);
    if (
      isLoggedIn ||
      testStarted ||
      testFinished ||
      doDont ||
      timer ||
      vue_token
    ) {
      // location.reload();
      localStorage.clear();
    }
  }
  checkForStartAndFinishTest(to, next);
};
// let isFirstTimeRoot = true;
router.beforeEach(async (to, from, next) => {
  console.log(to,"to",from,'from')
  var accesstoken;
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookiePair = cookies[i].split('=');
    if (cookiePair[0] === 'accessToken') {
      accesstoken=cookiePair[1]
    }
  }
  const uuid = to.query.uuid;
  if (to.path === "/" ) {
    const res1 = await tokenForUserMobileLogin();
    if (res1.status === 200) {
      const token = res1.data.token;
      const res = await getInternetAndSystemCheck(uuid, token);
      if (res.status === 200) {
        store.commit("sectionVUEX/saveSystemCheck", res.data.data.systemCheck);
        store.commit(
          "sectionVUEX/saveInternetCheck",
          res.data.data.internetCheck
        );
        const internetCheck = res.data.data.internetCheck;
        const systemCheck = res.data.data.systemCheck;
        // isFirstTimeRoot = false;
        var obj= {
          uuid: uuid,              
        }
        if(to.query.from=='candidate'){
          obj.from='candidate'
        }
        if (internetCheck) {
          next({
            path: "internetcheck",
            query: obj
          });
        } else if (systemCheck) {
       
         
          next({
            name: "SystemCheck",
            query:obj
          
          });
        } else { 
          //uncomment when query comes
          if(!to.query.from || to.query.from!='candidate'){
          next({
            name: "StudentLogin",
            query:obj
          });}
          // if(!accesstoken){
          //   next({
          //      name: "StudentLogin",
          //       query:obj
          //     });
          // }
          else{
            onSave(accesstoken, to.query.uuid, router)
          }
        }
   
      
      }
    }
  }

  checkBeforeRouting(to, next);
  return next();
});
export default router;
