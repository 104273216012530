<template>
  <div v-if="isLaptop">
    <div v-if="internetDisconnected" class="testscreen-custom-modal">
      <div class="internet-disconnected-modal-content">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Internet is disconnected
        </div>
        <div class="modalcloseicon pointer"></div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <svg
              width="132"
              height="131"
              viewBox="0 0 132 131"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M66.0003 92.8124C69.0132 92.8124 71.4557 95.2548 71.4557 98.2678C71.4557 101.281 69.0132 103.723 66.0003 103.723C62.9874 103.723 60.5449 101.281 60.5449 98.2678C60.5449 95.2548 62.9874 92.8124 66.0003 92.8124Z"
                fill="#4C78CE"
              />
              <path
                d="M110.688 56.0666C105.75 49.0146 99.2131 43.2311 91.6115 39.1894C84.0105 35.1476 75.5601 32.9623 66.9526 32.812C58.3446 32.6618 49.8233 34.5509 42.0855 38.3249C34.3476 42.0989 27.6128 47.6507 22.4316 54.5262M97.2818 70.9096C93.8253 65.973 89.2493 61.9246 83.9282 59.0954C78.6075 56.2662 72.6923 54.736 66.6668 54.6313C60.6413 54.5259 54.6764 55.8484 49.2599 58.4899C43.8434 61.1319 39.129 65.0183 35.5022 69.8311M79.4067 83.4259C77.9251 81.3103 75.9644 79.5749 73.6835 78.3627C71.4031 77.15 68.868 76.4943 66.286 76.4495C63.7034 76.4042 61.147 76.9711 58.8258 78.103C56.5045 79.2356 54.484 80.9011 52.9297 82.9638"
                stroke="#4C78CE"
                stroke-width="10.9108"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="26.8574"
                y="24.8892"
                width="13.4041"
                height="101.808"
                rx="6.70204"
                transform="rotate(-42.1739 26.8574 24.8892)"
                fill="#4C78CE"
                stroke="white"
                stroke-width="5"
              />
            </svg>
          </div>
          <div
            class="newmodal-body-content mt-4 d-flex align-items-center justify-content-center"
          >
            No internet connection found. Please check your internet to continue
            to take test.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-modal
      v-model="internetDisconnected"
      id="mobileInternetDisconnect"
      centered
      hide-footer
      hide-header
      class="p-0"
    >
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Internet is disconnected
        </div>

        <div class="mt-3 mobileScreen-TD-body-subhead text-center">
          No internet connection found. Please check your internet <br> to continue
          the test.
        </div>

        <div class="mt-3 d-flex justify-content-center mb-3 mt-4" style="width: 30%;">
          <b-button class="mobileScreen-startBtn w-100">Okay</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      internetDisconnected: false,
      isLaptop: true,
    };
  },
  mounted() {
    history.pushState(location.href, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    window.addEventListener("online", () => {
      this.internetDisconnected = false;
    });
    window.addEventListener("offline", () => {
      this.internetDisconnected = true;
      console.log("The network connection has been lost.");
    });
    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>
<style scoped>
.testscreen-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background overlay */
  z-index: 1500;
}
.testscreen-modal-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
  padding: 10px;
  position: relative;
  width: 650px;
}
.internet-disconnected-modal-content {
  border-radius: 18.743px;
  background: #fff;
  box-shadow: 0px 20.08215px 40.1643px 0px rgba(122, 122, 122, 0.2);
  width: 599.303px;
  height: fit-content;
  overflow: auto;
  padding: 12px;
  position: relative;
}
</style>
