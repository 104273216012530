<template>
  <div>
    <div>
      <div class="mobileLapToggle">
      </div>
      <AppMain />
    </div>
  </div>
</template>

<script>
import AppMain from "../../components/Layout/AppMain.vue";

export default {
  name: "Layout",
  components: {
    // Header,
    AppMain,
  },
  data() {
    return {};
  },
};
</script>

<style>
@media only screen and (max-width: 821px) {
  .mobileLapToggle {
    display: none;
  }
}
</style>
