import axios from "axios";
const imageVerify = axios.create({
  baseURL:
  "https://proctor.simplifymycareer.com/"
})
const coderequest = axios.create({
  baseURL:
    "https://code.simplifycv.com/"  //Development gateway
    // "https://pythoncode.simplifymycareer.com/" // Staging gateway
});
// "https://pythoncode.simplifymycareer.com/"
const serviceLicense = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/lis/" // Developent Gateway
    // "http://api.judge.simplifycv.com/lis/"  // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/lis/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/lis/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/lis/"  // Production Gateway
  // "https://assessment-api.hire3x.com/lis/"  // Production Endpoint

  // "http://127.0.0.1:8000/lis/" // Local Server
});
const serviceShare = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/share/" // Developent Gateway
    // "http://api.judge.simplifycv.com/proctoring/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/share/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/proctoring/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/proctoring/"  // Production Gateway
  // "https://assessment-api.hire3x.com/proctoring/"  // Production Endpoint

  // "http://127.0.0.1:8000/share/"
});
const serviceagent = axios.create({
  baseURL: "http://localhost:3000/",
});

const serviceCandidate = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/can/" // Developent Gateway
    // "http://api.judge.simplifycv.com/can/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/can/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/can/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/can/"  // Production Gateway
  // "https://assessment-api.hire3x.com/can/"  // Production Endpoint

  // "http://127.0.0.1:8000/can/" // Local Server
});
const serviceCandidateFakeToken = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/can/" // Developent Gateway
    // "http://api.judge.simplifycv.com/can/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/can/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/can/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/can/"  // Production Gateway
  // "https://assessment-api.hire3x.com/can/"  // Production Endpoint

  // "http://127.0.0.1:8000/can/" // Local Server
});
const serviceCandidatev2 = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/can/v2/" // Developent Gateway
    // "http://api.judge.simplifycv.com/can/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/can/v2/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/can/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/can/"  // Production Gateway
  // "https://assessment-api.hire3x.com/can/"  // Production Endpoint

  // "http://127.0.0.1:8000/can/" // Local Server
});

const serviceMapping = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/tma/" // Developent Gateway
    // "http://api.judge.simplifycv.com/tma/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/tma/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/tma/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/tma/"  // Production Gateway
  // "https://assessment-api.hire3x.com/tma/"  // Production Endpoint

  // "http://127.0.0.1:8000/tma/" // Local Server
});

const serviceQuestion = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/" // Developent Gateway
    // "http://api.judge.simplifycv.com/"   // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/"  // Production Gateway
  // "https://assessment-api.hire3x.com/"  // Production Endpoint

  // "http://127.0.0.1:8000/" // Local Server
});

const SysToken = axios.create({
  baseURL:
    "https://keynode.simplifycv.com/" // Development

    // "https://keynode.simplifymycareer.com/", // Staging

  // "https://keynode.hire3x.com/" // Production
});

const KeycloakAPIs = axios.create({
  baseURL:
    "https://auth.simplifymycareer.com/auth/", // Staging & Development

  // "https://auth.hire3x.com/auth/" // Production
});

const serviceProctoring = axios.create({
  baseURL:
    "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/assessment/proctoring/" // Developent Gateway
    // "http://api.judge.simplifycv.com/proctoring/" // Developent Endpoint

    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/assessment/proctoring/", // Staging Gateway
  // "https://assesment-api.simplifymycareer.com/proctoring/"  // Staging Endpoint

  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/assessment/proctoring/"  // Production Gateway
  // "https://assessment-api.hire3x.com/proctoring/"  // Production Endpoint

  // "http://127.0.0.1:8000/proctoring/"
});

export {
  serviceLicense,
  serviceShare,
  serviceCandidate,
  serviceMapping,
  serviceQuestion,
  SysToken,
  KeycloakAPIs,
  serviceagent,
  serviceProctoring,
  serviceCandidatev2,
  coderequest,
  imageVerify,
  serviceCandidateFakeToken
};
