<template>
    <div>
        <b-modal
      id="expirytestmodalLap"
      size="md"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div
        @click="$bvModal.hide('expirytestmodalLap')"
        class="modalcloseicon pointer"
      >
        <img class="rotate-on-hover" src="../../assets/newimgs/closeicon.svg" alt="" />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../../assets/newui-img/alertimage.svg" alt="" />
        </div>
        <div class="newmodal-body-content mt-4">
          {{ apiResponse }}
        </div>
        <div class="bluebtns mt-5">
          <b-button
            variant="primary"
            class="px-5"
            @click="$bvModal.hide('expirytestmodalLap')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal id="mobileLoginAlert" centered hide-footer hide-header class="p-0">
      <div
        class="m-4 d-flex align-items-center justify-content-center flex-column"
      >
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>

        <div class="mt-3 mobileScreen-modal-subhead text-center">
          {{ apiResponse }}
        </div>

        <div class="mt-4 mobileScreen-TS-secName text-center w-50">
          <b-button
            class="mobileScreen-startBtn w-100"
            @click="$bvModal.hide('mobileLoginAlert')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
    </div>
</template>
<script>
import eventBus from '../../utils/eventBus.js';
export default{
name:"LoginModals",
    created() {
    eventBus.$on('show-modal', this.displayModal);
  },
  beforeDestroy() {
    eventBus.$off('show-modal', this.displayModal);
  },
  data(){
    return{
        apiResponse: null,
    }
  },
  methods:{
    displayModal(response) {
      console.log(response,"resp")
      this.apiResponse = response;

        let width=window.innerWidth;
        if(width<820){
            this.$bvModal.show('mobileLoginAlert')
        }
        else{
            this.$bvModal.show('expirytestmodalLap')
 
        }
    },
  }
}
</script>