import Vue from "vue";
import Vuex from "vuex";
import {
  getTestResponse,
  putAnswer,
  candidateteststatsPost,
  getAnsweredCount,
} from "../../apiFunction";

Vue.use(Vuex);
const state = () => ({
  allAnswer: [],
  responseAvail: true,
  sectionDetails: [],
  questionData: [],
  selectedSection: {},
  totalanswered: {},
  selectedSectionForOverview: {},
  storeQuetionsForcoding: 0,
  storeQuestiontoexecute: {},
  storeQuetionschildindex: null,
  storeQuetionparentIndex: null,
  execute: false,
  screenShareEnabled: false,
  viewRemarks: false,
});
const actions = {
  async testCount(state, payload) {
    try {
      const res = await getAnsweredCount(payload);
      state.commit("savetotalanswered", res.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getTestResponse(state, payload) {
    try {
      const res = await getTestResponse(
        payload.topicID,
        payload.testID,
        payload.groupID,
        payload.userID,
        payload.attemptNo
      );
      console.log(res);
      if (res.status == 200) {
        await state.commit("saveSectionDetails", res.data.data.testResponse);
        await state.commit("updateAttemptedCount");
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  async saveAnswer(state, payloads) {
    state.state.allAnswer = []
    if (payloads != "recall") {
      state.state.allAnswer.push(payloads);
    }
    if (state.state.responseAvail) {
      let payload = state.state.allAnswer[0];
      // state.state.responseAvail = false;
      try {
        const res = await putAnswer(payload);
        await state.commit("updateAttemptedCount");
        let payload1 = {};
        payload1.testID = payload.testID;
        payload1.topicID = payload.topicID;
        payload1.groupID = payload.groupID;
        payload1.attemptNo = payload.attemptNo;
        await state.dispatch("testCount", payload1);
        if (res.status == 200) {
          // await state.dispatch("testCount", payload1);
          state.state.responseAvail = true;
          state.state.allAnswer.splice(0, 1);
          if (state.state.allAnswer.length > 0) {
            await state.dispatch("saveAnswer", "recall");
          }
        }
      } catch (error) {
        state.state.allAnswer=[];
        state.state.responseAvail = true;
        console.log(error);
      }
    } else {
      console.log("Item with questionKey not found in the array.");
    }
  },
  async postStatsData(state, payload) {
    try {
      const res = await candidateteststatsPost(payload);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
};
const getters = {
  getSectionDetails: (state) => state.sectionDetails,
  getSelectedSection: (state) => state.selectedSection,
  getQuestionData: (state) => state.questionData,
  gettotalanswered: (state) => state.totalanswered,
  getSelectedSectionForOverView: (state) => state.selectedSectionForOverview,
  getscreenShareEnabled: (state) => state.screenShareEnabled,
  getstoreQuetionsForcodingall: (state) => state.storeQuetionsForcoding,
  getstoreQuestiontoexecute: (state) => state.storeQuestiontoexecute,
  getstorestoreQuetionschildindex: (state) => state.storeQuetionschildindex,
  getstorestoreQuetionparentIndex: (state) => state.storeQuetionparentIndex,
  getexecute: (state) => state.execute,
  getallAnswer: (state) => state.allAnswer,
  getviewRemarks: (state) => state.viewRemarks,
};

// mutations
const mutations = {
  RESET_ALL_STATE(state) {
    state.allAnswer = [];
    state.responseAvail = true;
    state.sectionDetails = [];
    state.questionData = [];
    state.selectedSection = {};
    state.totalanswered = {};
    state.selectedSectionForOverview = {};
    state.storeQuetionsForcoding = 0;
    state.storeQuestiontoexecute = {};
    state.storeQuetionschildindex = null;
    state.storeQuetionparentIndex = null;
    state.execute = false;
    state.screenShareEnabled = false;
    state.viewRemarks=false;
  },
  storeScreenShareEnable(state, payload) {
    state.screenShareEnabled = payload;
  },
  storeresponseAvail(state, payload) {
    state.responseAvail = payload;
  },
  storeQuetionsForProgramming(state, payload) {
    state.storeQuetionsForcoding = payload;
  },
  storeQuetionparentIndex(state, payload) {
    state.storeQuetionparentIndex = payload;
  },
  storeQuetionschildindex(state, payload) {
    state.storeQuetionschildindex = payload;
  },
  storeQuetion(state, payload) {
    state.storeQuestiontoexecute = payload;
  },
  execute(state, payload) {
    state.execute = payload;
  },
  setViewRemarks(state, payload) {
    state.viewRemarks = payload;
  },
  saveSectionDetails(state, payload) {
    state.sectionDetails = payload;
    const transformedDatawithcoding = state.sectionDetails
      .filter((item) => item.codingSection)
      .map((item) => {
        return {
          ...item,
          attemptedCount: 0,
        };
      });
    const transformedDatawithoutcoding = state.sectionDetails
      .filter((item) => !item.codingSection)
      .map((item) => {
        return {
          ...item,
          attemptedCount: 0,
          codingSection: false,
        };
      });
    let createSingleObject = {
      sectionName: "programming",
      sectionID: "1234567890ARUN",
      codingSection: true,
      serctionTotalMarks: 233,
      sectionResponse: [...transformedDatawithcoding],
    };
    // state.sectionDetails = [...transformedDatawithoutcoding,createSingleObject]
    if (transformedDatawithcoding.length > 0) {
      state.sectionDetails = [
        ...transformedDatawithoutcoding,
        createSingleObject,
      ];
    } else {
      state.sectionDetails = [...transformedDatawithoutcoding];
    }
  },
  savetotalanswered(state, payload) {
    state.totalanswered = payload;
  },
  clearSelectedSection(state, payload) {
    state.selectedSection = payload;
  },
  saveSelectedSection(state, payload) {
    if (payload.sectionName != "programming") {
      state.selectedSection = payload;
      state.questionData = payload.sectionResponse;
      for (let i in state.questionData) {
        if (state.questionData[i].questionKey.slice(0, 4) == "MAFO") {
          if (!Array.isArray(state.questionData[i].answer)) {
            state.questionData[i].answer = [];
            mutations.pushAnsKey(state.questionData[i])
          }
        }
      }
    } else {
      console.log(payload.sectionResponse);
      state.selectedSection = payload;
    }
  },

  pushAnsKey(res) {
    for (let i in res.questionBody.answerKey) {
      res.answer.push(res.questionBody.answerKey[i].sideB);
    }
  },

  saveSelectedSectionForOverview(state, payload) {
    state.selectedSectionForOverview = payload;
  },
  updateSectionDetails(state, payload) {
    let index1 = state.selectedSection.sectionResponse.findIndex(
      (i) => i.questionKey === payload.questionKey
    );
    state.selectedSection.sectionResponse[index1].answer = payload.answer;
    state.selectedSection.sectionResponse[index1].answerStatus =
      payload.answerStatus;
    state.selectedSection.attemptedCount = 0;
    let index = state.sectionDetails.findIndex(
      (i) => i.sectionID === payload.sectionID
    );
    state.sectionDetails[index].sectionResponse[index1].answer = payload.answer;
    state.sectionDetails[index].sectionResponse[index1].answerStatus =
      payload.answerStatus;
    for (let j in state.selectedSection.sectionResponse) {
      if (state.selectedSection.sectionResponse[j].answerStatus.attempted) {
        state.selectedSection.attemptedCount += 1;
      }
    }
  },
  updateCodingSectionDetails(state, {payload, sectionID}) {
    let index1 = state.selectedSection.sectionResponse.findIndex(
      (i) => i.sectionID === sectionID
    );
    let index2 = state.selectedSection.sectionResponse[index1].sectionResponse.findIndex(
      (i) => i.questionKey === payload.questionKey
    );

    state.selectedSection.sectionResponse[index1].sectionResponse[index2].answer = payload.answer;
    state.selectedSection.sectionResponse[index1].sectionResponse[index2].answerStatus =
      payload.answerStatus;
    state.selectedSection.attemptedCount = 0;
    let index = state.sectionDetails.findIndex(
      (i) => i.sectionID === state.selectedSection.sectionID
    );

    state.sectionDetails[index].sectionResponse[index1].sectionResponse[index2].answer = payload.answer;
    state.sectionDetails[index].sectionResponse[index1].sectionResponse[index2].answerStatus =
      payload.answerStatus;
    for (let j in state.selectedSection.sectionResponse) {
      for(let k in state.selectedSection.sectionResponse[j].sectionResponse){

      if (state.selectedSection.sectionResponse[j].sectionResponse[k].answerStatus.attempted) {
        state.selectedSection.attemptedCount += 1;
      }
    }
    }
  },
  updateAttemptedCount(state) {
    for (let i in state.sectionDetails) {
      if (state.sectionDetails[i].sectionName != "programming") {
        state.sectionDetails[i].attemptedCount = 0;
        for (let j in state.sectionDetails[i].sectionResponse) {
          if (
            state.sectionDetails[i].sectionResponse[j].answerStatus.attempted
          ) {
            state.sectionDetails[i].attemptedCount += 1;
          }
        }
      } else {
        mutations.countUpdateElsePart(state.sectionDetails[i])
      }
    }
  },

  countUpdateElsePart(res) {
    res.attemptedCount = 0;
    for (let i in res.sectionResponse) {
      for (let j in res.sectionResponse) {
        if (res.sectionResponse[i].sectionResponse[j]) {
          if (
            res.sectionResponse[i].sectionResponse[j].answerStatus.attempted
          ) {
            res.attemptedCount += 1;
          }
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
