import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueHtml2Canvas from "vue-html2canvas";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";
//Global css file
import "@/assets/globalcss/style.css";
import vuex from "vuex";
import LoginModals from "./components/modals/LoginToastModals"
Vue.use(VueHtml2Canvas);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(vuex);
Vue.use(Vuelidate);
Vue.component('LoginModals', LoginModals);
Vue.config.productionTip = false;
// Create WebSocket connection.
// "http://localhost:8080/"
// "http://sctimer.simplifycv.com/"
// "http://144.126.252.222:8080/");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("sectionname", function(value) {
  if (value.length <= 16) {
    return value;
  } else {
    return value.slice(0, 15) + "...";
  }
});

let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          let excludedEl = vnode.context.$refs[refName];
          excludedEl = excludedEl instanceof Vue ? excludedEl.$el : excludedEl;
          // Get the element using the reference name
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl) {
            // See if this excluded element
            // is the same element the user just clicked on
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener("click", handleOutsideClick);
  },
  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener("click", handleOutsideClick);
  },
});
function filterControlCharacters(value) {
  return value
    .split("")
    .filter((char) => char.charCodeAt(0) >= 32 || char.charCodeAt(0) === 9)
    .join("");
}

function safeHTML(htmlString) {
  let doc = document.implementation.createHTMLDocument("");

  let div = doc.createElement("div");

  div.innerHTML = htmlString;

  for (
    let elements = div.querySelectorAll("*"), i = elements.length - 1;
    i >= 0;
    i--
  ) {
    let element = elements[i],
      tagName = element.localName;

    if (
      tagName == "script" ||
      tagName == "noscript" ||
      tagName == "noembed" ||
      !(element.attributes instanceof NamedNodeMap)
    ) {
      try {
        element.parentNode.removeChild(element);
      } catch (e) {
        element.outerHTML = "";
      }

      continue;
    }

    if (!element.hasAttributes()) continue;

    for (
      let attributes = element.attributes, j = attributes.length - 1;
      j >= 0;
      j--
    ) {
      let attribute = attributes[j],
        attributeName = attribute.localName,
        attributeValue = filterControlCharacters(attribute.value.trim());

      handleAttribute(element, attributeName, attributeValue);
    }
  }

  return div.innerHTML;
}

function handleAttribute(element, attributeName, attributeValue, tagName) {
  if (
    attributeName.startsWith("on") ||
    (["src", "href"].includes(attributeName) &&
      attributeValue.indexOf("javascript:") === 0) ||
    (["audio", "image", "img", "source", "video"].indexOf(tagName) === -1 &&
      ["src", "data"].includes(attributeName) &&
      attributeValue.indexOf("data:") === 0)
  ) {
    element.removeAttribute(attributeName);
  }
}

Vue.directive("html-safe", {
  // For Vue 2.x

  inserted: function(el, binding) {
    el.innerHTML = safeHTML(binding.value);
  },

  // For Vue 2.x

  update: function(el, binding) {
    if (binding.value !== binding.oldValue)
      el.innerHTML = safeHTML(binding.value);
  },
});
