import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const state = () => ({
  openCodingSection:false,
  dataForQuestion:{},
  tryQuestion:false,
  moveBtn:0
});
const actions = {
//   async testCount(state, payload) {
//     try {
//       const res = await getAnsweredCount(payload);
//       state.commit('savetotalanswered', res.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   },
};

const getters = {
  getopenCodingSection: state => state.openCodingSection,
  getdataForQuestion: state => state.dataForQuestion,
  gettryQuestion: state => state.tryQuestion,
  getmoveBtn: state => state.moveBtn,
};

// mutations
const mutations = {
  RESET_ALL_STATE(state){
    state.openCodingSection=false
    state.dataForQuestion={}
    state.tryQuestion=false
    state.moveBtn=0
  },
  openCodingSection(state,payload){
    state.openCodingSection = payload
  },
  savetryQuestion(state, payload) {
    state.tryQuestion = payload;
  },
  datafortryquestion(state,payload){
    state.dataForQuestion = payload
  },
  storemoveBtn(state, payload){
    state.moveBtn = payload
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
