import Vuex from "vuex";
import sectionVUEX from "./modules/sectionVUEX.js";
import questionVUEX from "./modules/questionVUEX.js";
import socketVUEX from "./modules/socketVUEX.js";
import testVUEX from "./modules/testVUEX.js";
import codingVUEX from "./modules/codingVUEX.js";
export default new Vuex.Store({
  modules: {    
    sectionVUEX,
    questionVUEX,
    socketVUEX,
    codingVUEX,
    testVUEX
  }
});