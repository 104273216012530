import Vue from "vue";
import Vuex from "vuex";
import {
  postMarking,
} from "../../apiFunction.js";
import store from "../index.js";

Vue.use(Vuex);
const state = () => ({
  // SECTION VUE state
  selectedCodingSectionIndex:null,
  systemCheck: false,
  internetCheck: false,
  currentQuestionIndex: 0,
  testtaken: false,
  communicationtestSubmit: false,
  testtimeleftintimeformat: "00:00",
  testtimeleft: 0,
  sectionId: 0,
  sectionDetails: [],
  sectionAPI: true,
  selectedSection: {},
  testDetails: [],
  prevbtn: true,
  nextbtn: false,
  tabIndex: 0,
  statslocation: {},
  userDetails: {},
  // Preloader
  isLoadingStatus: false,
  time: {
    start: "",
    end: "",
    duration: "",
  },
  licensekey: "",
  userID: "",
  storeSectionIndex: 0,
  uuid: "",
});

const getters = {
  // ---------------------------
  // -------- SECTIONS ---------
  // ---------------------------
  getSelectedCodingSectionIndex: (state) => state.selectedCodingSectionIndex,
  getSystemCheck: (state) => state.systemCheck,
  getInternetCheck: (state) => state.internetCheck,
  getSectionDetails: (state) => state.sectionDetails,
  getStatsLocation: (state) => state.statslocation,
  getlicensekey: (state) => state.licensekey,
  SectionAPIStatus: (state) => state.sectionAPI,
  getSelectedSection: (state) => state.selectedSection,
  getTestDetails: (state) => state.testDetails,
  getIsLoadingStatus: (state) => state.isLoadingStatus,
  getTime: (state) => state.time,
  getUserID: (state) => state.userID,
  getUUID: (state) => state.uuid,
  getNextBtn: (state) => state.nextbtn,
  getPrevBtn: (state) => state.prevbtn,
  getTabIndex: (state) => state.tabIndex,
  getUserDetails: (state) => state.userDetails,
  getstoreSectionIndex: (state) => state.storeSectionIndex,
  getsectionId: (state) => state.sectionId,
  gettesttimeleft: (state) => state.testtimeleft,
  gettesttaken: (state) => state.testtaken,
  gettesttimeleftintimeformat: (state) => state.testtimeleftintimeformat,
  gettestSubmit: (state) => state.communicationtestSubmit,
  getcurrentQuestionIndex: (state) => state.currentQuestionIndex,
};

// actions
const actions = {
  // -------------------------
  // ------- SECTION ---------
  // -------------------------
  async saveSectionAPIStatus(state, payload) {
    state.commit("saveSectionAPIStatus", payload);
  },

  async postMarking(state, payload) {
    try {
      const res = await postMarking(payload);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  },
};

// mutations
const mutations = {
  RESET_ALL_STATE(state) {
      state.selectedCodingSectionIndex = null
      state.systemCheck = false
      state.internetCheck = false
      state.currentQuestionIndex = 0
      state.testtaken = false
      state.communicationtestSubmit = false
      state.testtimeleftintimeformat = "00:00"
      state.testtimeleft = 0
      state.sectionId = 0
      state.sectionDetails = []
      state.sectionAPI = true
      state.selectedSection = {}
      state.testDetails = []
      state.prevbtn = true
      state.nextbtn = false
      state.tabIndex = 0
      state.statslocation = {}
      state.userDetails = {}
      state.isLoadingStatus = false
      state.time = {
        start: "",
        end: "",
        duration: "",
      }
      state.licensekey = ""
      state.userID = ""
      state.storeSectionIndex = 0
      state.uuid = ""
  },
  // -----------------------
  // ------- SECTION -------
  // -----------------------
  saveSelectedCodingSectionIndex(state, payload) {
    state.selectedCodingSectionIndex = payload;
  },
  currentQuestionIndex(state, payload) {
    state.currentQuestionIndex = payload;
  },
  saveIsCommunicationTestStatus(state, payload) {
    state.communicationtestSubmit = payload;
  },
  saveSystemCheck(state, payload) {
    state.systemCheck = payload;
  },
  saveInternetCheck(state, payload) {
    state.internetCheck = payload;
  },
  saveremaininigtesttime(state, payload) {
    state.testtimeleft = payload;
    state.testtimeleft = state.testtimeleft - 1;
  },
  saveremaininigtesttimeintimeformat(state, payload) {
    state.testtimeleftintimeformat = payload;
  },
  testtaken(state, payload) {
    state.testtaken = payload;
  },
  saveUUID(state, payload) {
    state.uuid = payload;
  },
  savelicensekey(state, payload) {
    state.licensekey = payload;
  },
  saveSelectedSectionFromCode(state, payload) {
    state.storeSectionIndex = payload;
  },
  saveUserDetails(state, payload) {
    state.userDetails = payload;
  },
  saveUserID(state, payload) {
    state.userID = payload;
  },
  saveSectionAPIStatus(state, payload) {
    state.sectionAPI = payload;
  },
  savestatsLoaction(state, payload) {
    state.statslocation = payload;
  },
  saveSection(state, payload) {
    if (state.sectionAPI) {
      state.sectionDetails = payload;
    }
  },
  saveSelectedSection(state, payload) {
    state.selectedSection = payload;
  },
  saveTestDetails(state, payload) {
    state.testDetails = payload;
  },
  saveTabIndex(state, payload) {
    if (payload == "next") {
      state.tabIndex++;
    } else if (payload == "prev") {
      state.tabIndex--;
    } else if (payload.status == "set") {
      state.tabIndex = payload.value;
    }
  },
  // For Loading
  isLoadingStatus(state, payload) {
    state.isLoadingStatus = payload;
  },
  prevnextbtnstatus(state, payload) {
    state.sectionId = payload;
    console.log(state.sectionId);
    if (payload == 0) {
      if (payload === store.state.testVUEX.sectionDetails.length - 1) {
        state.nextbtn = true;
      } else {
        state.nextbtn = false;
      }
      state.prevbtn = true;
    } else if (payload == store.state.testVUEX.sectionDetails.length - 1) {
      state.nextbtn = true;
      state.prevbtn = false;
    } else {
      state.nextbtn = false;
      state.prevbtn = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
